import flatpickr from 'flatpickr'
import { Polish } from 'flatpickr/dist/l10n/pl'

export default function setupDatePickers() {
  flatpickr('.datepicker', {allowInput: true, locale: Polish})
  flatpickr('.datetimepicker', {allowInput: true, locale: Polish, enableTime: true, time_24hr: true})
  flatpickr('.timepicker',
    {allowInput: true, locale: Polish, enableTime: true, noCalendar: true, dateFormat: 'H:i', time_24hr: true})
  flatpickr('.datetimerangepicker', {allowInput: true, locale: Polish, mode: 'multiple'})
  flatpickr('.daterangepicker', {
    allowInput: true,
    locale: Polish,
    mode: 'range',
    onChange: function(dates) {
      if (!dates[0] || !dates[1]) {
        document.getElementById(this.input.dataset.fromInput).value = null;
        document.getElementById(this.input.dataset.toInput).value = null;
      } else {
        document.getElementById(this.input.dataset.fromInput).value = dates[0];
        document.getElementById(this.input.dataset.toInput).value = dates[1];
      }

      this.input.form.requestSubmit();
    },
  })
}
